<template>
  <div v-if="computedValues?.link" class="atom-cta">
    <component
      :is="isDisabled ? 'div' : 'SlotLink'"
      v-if="(computedValues?.link || (data?.link && data?.link?.cached_url)) || link"
      :url="computedValues.link?.cached_url
        || computedValues.link || data?.link?.cached_url || link"
      class="no-underline on-grid"
    >
      <AtomButton
        :data="computedValues"
        :is-disabled="isDisabled"
      />
    </component>
  </div>
</template>

<script setup>
const props = defineProps({
    /* Props for storyblok data */
    data: {
        type: Object,
        default: () => {},
    },

    /* Props for manual calling */
    buttonType: {
        type: String,
        default: 'button', // button || submit ||  reset
    },
    appereance: {
        type: String,
        default: 'primary', // Primary || Secondary
    },
    text: {
        type: String,
        default: '',
    },
    link: {
        type: [String, Boolean],
        default: false,
    },
    isDisabled: {
        type: Boolean,
        default: false,
    },
});

const user = useStrapiUser();
const storyStore = useStoryStore();

/* Computed Storyblok Data */
const computedValues = computed(() => {
    const data = {
        button_type: props.data?.button_type || props.buttonType,
        appereance: props.data?.appereance || props.appereance,
        text: props.data?.text || props.text,
        link: props.data?.link || props.link,
    };

    if (props.data?.alternative_guest_link?.cached_url && !user.value) {
        data.link = props.data?.alternative_guest_link?.cached_url;
    }

    if (props.data?.strapi_data_key) {
        data.link = storyStore.getConnectedStrapiValue(props.data.strapi_data_key);
    }

    if (props.data?.alternative_guest_link?.cached_url && !user.value) {
        data.link = props.data.alternative_guest_link;
    }

    if (!data.link) {
        data.link = '';
    }

    return data;
});

</script>

  <style lang="scss">
    .atom-cta {
        @include top-level-only {
            @include default-content-columns;
            @include fluid('margin-bottom', 30px, 120px);
        }
    }
  </style>
